<template>
    <div class="container mt-2">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">Менеджер тестів курсу</div>

                    <div class="card-body">


                        <div v-for="(test, testIndex) in tests" :key="test.id">
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="">Запитання №{{ testIndex + 1 }}:</label>
                                        <button @click="removeTest(testIndex)" class="fas fa-trash-alt"
                                                type="button"></button>
                                        <input v-model="test.title" placeholder="Що таке ..." type="text"
                                               class="form-control" id="">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Тип теста</label>
                                        <select v-model="test.type" @change="resetTestAnswer(test)"
                                                class="form-control">
                                            <option value="0">Виберіть тип тесту</option>
                                            <option v-for="typeOftest in typeOfTests" v-bind:value="typeOftest.key">
                                                {{ typeOftest.text }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="test.type !== '0'" class="form-group">

                                        <div v-for="(option, key) in test.options" :key="option.id"
                                             class="input-group mb-3">

                                            <span class="input-group-text" id="basic-addon1">{{ key + 1 }}</span>
                                            <input v-model="option.title" placeholder="Варіант відповіді" type="text"
                                                   class="form-control">
                                            <div class="input-group-text">
                                                <input class="" :type="test.type" :name="'option_' + test.id"
                                                       :value="option.id" v-model="test.answer">
                                            </div>
                                            <button @click="removeOption(key, test)" class="fas fa-trash-alt"
                                                    type="button"></button>
                                        </div>

                                        <button @click="addNewOption(test)" type="button" class="btn btn-dark mt-2">
                                            Додати варіант відповіді
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2">
                            <button @click="addNewTest" class="btn btn-dark">Додати запитання</button>
                        </div>

                        <div class="d-flex mt-4">
                            <button @click="save()" class="btn btn-primary w-100" type="button">Зберегти зміни</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    props: {
        getTestUrl: String,
        saveTestsUrl: String,
        courseUrl: String,
        innerTypeOfTests: Array
    },
    data: function () {
        return {
            tests: null,
            typeOfTests: this.innerTypeOfTests
        }
    },
    mounted() {
        axios
            .get(this.getTestUrl)
            .then(response => {
                if (response.status === 200) {
                    this.tests = response.data
                }
            })
            .catch(function (error) {
                if (typeof error.response.data === "string") {
                    Vue.$toast.open({...window.vueToastOptionsE, message: error.response.data});
                } else {
                    Vue.$toast.open({...window.vueToastOptionsE, message: 'Помилка в збережені'});
                }
            });
    },
    methods: {
        addNewTest: function () {
            this.tests.push({
                'id': window.uid(),
                'title': '',
                'type': '0',
                'options': [],
                'answer': null
            });
        },
        removeTest: function (testIndex) {
            this.tests.splice(testIndex, 1)
        },
        addNewOption: function (test) {
            test.options.push({
                'id': window.uid(),
                'title': ''
            });
        },
        removeOption: function (optionKey, test) {
            if (test.type === 'radio' && test.answer === test.options[optionKey].id) {
                this.resetTestAnswer(test);
            } else if (test.type === 'checkbox' && test.answer.includes(test.options[optionKey].id)) {
                test.answer.splice(optionKey, 1)
            }
            test.options.splice(optionKey, 1)
        },
        resetTestAnswer: function (test) {
            if (test.type === 'radio') {
                test.answer = null;
            } else if (test.type === 'checkbox') {
                test.answer = [];
            }
        },
        save: function (redirect = this.courseUrl) {
            axios.post(this.saveTestsUrl, {tests: this.tests})
                .then(function (response) {
                    Vue.$toast.open({...window.vueToastOptionsS, message: 'Успішно збережено'});
                    window.location.href = redirect;
                })
                .catch(function (error) {
                    if (error.response.data instanceof Object) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            let values = key.split('.');
                            Vue.$toast.open({
                                ...window.vueToastOptionsE,
                                message: 'Помилка в запитанні №' + (++values[1]) + ' в полі ' + values[2]
                            });
                        }
                    } else if (typeof error.response.data === "string") {
                        Vue.$toast.open({...window.vueToastOptionsE, message: error.response.data});
                    } else {
                        Vue.$toast.open({...window.vueToastOptionsE, message: 'Помилка в збережені'});
                    }

                });
        }
    }
}
</script>
