const datepickerRules = {
    formatter: (input, date, instance) => {

        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

        input.value = `${ye}-${mo}-${da}`;
    },
    customDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
    customMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}


window.onload = function () {

    if(!!document.querySelector('.start-date') && !!document.querySelector('.end-date')) {
        const start = datepicker('.start-date', {
            id: 1,
            ...datepickerRules

        })
        const end = datepicker('.end-date', {
            id: 1,
            ...datepickerRules
        })

        start.getRange()
        end.getRange()

    }


}
