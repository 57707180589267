<template>
    <div>
        <div class="form-check form-switch" >
            <input class="form-check-input" type="checkbox" role="switch" id="flex-switch-check-default"
                   v-model="checked"
                   v-bind:disabled = checkingDisabled()
                   v-on:click="save()"
            >
            <label class="form-check-label" for="flex-switch-check-default">
                <span class="badge bg-warning" v-if="checkingDisabled()">
                    <a v-bind:href="urlEdit">Встановити тривалість</a>
                </span>
                <span class="badge bg-success" v-else-if=checkingChecked>
                    {{ timerDuration }} хв
                </span>
                <span class="badge bg-secondary" v-else>
                    вимкнено
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    props: {
        urlEdit: String,
        status: Number,
        urlTimerSwitcher: String,
        timerDuration: Number
    },
    data: function () {
        return {
            checked: this.status,
        }
    },
    methods: {
        save() {
            if(!this.timerDuration){
                Vue.$toast.open({...window.vueToastOptionsS, message:'Не встановлена тривалість таймера', type: "warning"});
            }
            axios.get(this.urlTimerSwitcher)
                .then(function () {
                    Vue.$toast.open({...window.vueToastOptionsS, message: 'Успішно збережено'});
                })
                .catch(function (error) {
                    if (typeof error.response.data === "string") {
                        Vue.$toast.open({...window.vueToastOptionsE, message: error.response.data});
                    } else {
                        Vue.$toast.open({...window.vueToastOptionsE, message: 'Помилка в збережені'});
                    }
                })
            this.checked = !this.checked;
        },
        checkingDisabled () {
            return !this.timerDuration;
        },
    },
    computed: {
        checkingChecked () {
            return this.checked;
        }
    }
}
</script>
