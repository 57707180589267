<template>
    <div>
        <h4>Проходження курсу "{{ course.title }}"</h4>

        <div class="row" v-if="status === 'doing'">
            <div class="col-md-10">
                <div class="d-flex justify-content-between mt-2">
                    <button type="button" class="btn btn-outline-primary" v-on:click="prevTest()" :class="active !== 0 ? '' : 'v-hidden'">Назад</button>
                    <p>{{ active + 1 }}/{{ total + 1 }}</p>
                    <button type="button" class="btn btn-outline-primary" v-on:click="nextTest()" :class="active !== total ? '' : 'v-hidden'">Вперед</button>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-10">

                <form>

                    <div v-for="(test, index) in tests" :key="index" v-show="index === active" class="card mt-4">
                        <div class="card-body">
                            <h5 class="card-title">{{ test.title }}
                                <small>({{ test.type === 'radio' ? 'Одна відповідь' : 'Декілька відповідей' }})</small>
                            </h5>

                            <div v-for="option in test.options" class="form-group">
                                <div class="form-check">
                                    <input :id="option.uid" class="form-check-input" v-on:change="changeInput(test, option.uid)" :type="test.type" :name="test.uid" :value="option.uid">
                                    <label :for="option.uid" class="form-check-label">
                                        {{ option.title }}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                </form>
            </div>
            <div class="col-md-2">
                <div class="card mt-4" :class="this.isTimeIsRunningOut ? 'border-red': ''">
                    <div class="card-body">
                        <h6 v-if="timer !== null" class="card-title text-center" :class="this.isTimeIsRunningOut ? 'text-danger fw-bold': ''">
                            Залишилось:
                            <h5 class="text-center mt-2" :class="this.isTimeIsRunningOut ? 'text-danger fw-bold': ''">{{ timeFormatted }}</h5>
                        </h6>
                        <h6 v-else class="card-title">
                            Час необмежений
                        </h6>

                        <div class="d-flex justify-content-center m-4">
                            <button type="submit" class="btn text-white" :class="this.isTimeIsRunningOut ? 'btn-danger' : 'btn-primary'" v-on:click.prevent="sendResult">Завершити</button>
                        </div>

                    </div>

                    <div class="alert alert-danger text-center" role="alert" v-show="isTimeIsRunningEnding">
                        Увага! <br />    Залишилось мало часу!
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="status === 'sending'" class="w-100 mt-2">
            <h5 class="text-center">Обробляємо ваші дані, очікуйте...</h5>
        </div>
        <div v-else-if="status === 'done'" class="w-100 mt-2">

            <h4 v-if="responseData.passed" class="text-center text-success">Здано</h4>
            <h4 v-else class="text-center text-danger">Не здано</h4>

            <h5 class="text-center">Ваш бал: {{ responseData.mark }}</h5>
            <h6 class="text-center">Мінімальний бал для здачі курсу: {{ course.minimal_mark }}</h6>
            <div class="d-flex justify-content-center">
                <a :href="courseUrl" class="btn btn-primary">Повернутись до кабінету</a>
            </div>

        </div>
        <div v-else-if="status === 'error'" class="w-100 mt-2">
            <h4 class="text-center text-danger">{{responseData}}</h4>
        </div>
    </div>
</template>

<script>

import TimeParser from "../time-parser";

export default {
    name: "AttemptManager",

    props: {
        innerTests: Array,
        course: Object,
        finishUrl: String,
        courseUrl: String
    },
    data: function () {
        return {
            tests: this.innerTests,
            typeOfTests: this.innerTypeOfTests,
            result: {},
            active: 0,
            total: (this.innerTests.length - 1),

            timer: this.course.timer_switcher === 1 ? this.course.timer_duration * 60 : null,

            status: 'doing',
            responseData: {}
        }
    },
    methods: {
        sendResult() {
            this.status = 'sending';

            axios
                .post(this.finishUrl, this.result)
                .then((response) => {
                    this.status = 'done'
                    this.responseData = response.data;
                })
                .catch((error) => {
                    this.status = 'error'
                    this.responseData = error.response.data;
                })
        },
        changeInput(test, optionUid) {

            const testUid = test.uid

            if (this.result.hasOwnProperty(testUid) && test.type === 'checkbox') {
                if (!this.result[testUid].includes(optionUid)) {
                    this.result[testUid].push(optionUid);
                } else {
                    this.result[testUid].splice(this.result[testUid].indexOf(optionUid), 1);
                }
            } else {
                this.result[testUid] = [optionUid];
            }
        },
        nextTest() {
            this.active++;
        },
        prevTest() {
            this.active--;
        }
    },
    created() {
        window.addEventListener("beforeunload", (e) => {
            if (this.status === 'done' || this.status === 'error') {
                return false
            }
            let confirmationMessage = "Ви впевнені що хочете закрити стоірнку. В такому випадку проходження курсу не збережеться а ваша спроба буде зарахована";

            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage;                            //Webkit, Safari, Chrome
        });

        setInterval(() => {
            if (this.status === 'doing' && this.timer > 0) {
                this.timer--
            }
        }, 1000); // update about every second

    },
    watch: {
        timer: function () {
            if(this.timer===0) {
                this.sendResult()
            }
        }
    },
    computed: {
        timeFormatted() {
            return TimeParser.secondsToTime(this.timer)
        },
        isTimeIsRunningOut() {
            return this.timer < 300; // за 5 хв
        },

        isTimeIsRunningEnding() {
            return this.timer < 60; // за 1 хв
        }
    }
}
</script>

<style scoped>
</style>
