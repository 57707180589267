require('./bootstrap');
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import TimeParser from "./components/time-parser";

Vue.use(VueToast);
Vue.use(TimeParser);

window.vueToastOptionsE = {
    type: 'error',
    position: 'top-right',
    duration: 3000
};

window.vueToastOptionsS = {
    type: 'success',
    position: 'top-right',
    duration: 3000
};

require('alpinejs');

// custom
require('./components/datepicker');

window.uid = function () {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

Vue.component('test-manager', require('./components/vue/TestManager').default);
Vue.component('attempt-manager', require('./components/vue/AttemptManager').default);
Vue.component('timer', require('./components/vue/SwitchTimer').default);

const app = new Vue({
    el: '#app'
});


if (!!document.querySelector('.course-table')) {
    const COURSE_TABLE = [...document.querySelectorAll('.course-table')];
    COURSE_TABLE.forEach(
        function (item) {
            item.addEventListener('click', function (e) {
                if (!!e.target.closest('.course-link')) {
                    let link = e.target.closest('.course-link')
                    window.location.href = "/courses/" + link.dataset.id;
                }
            })
        }
    )
}

if (!!document.querySelector('.tests-categories-table')) {
    const TESTS_CATEGORIES_TABLE = [...document.querySelectorAll('.tests-categories-table')];
    TESTS_CATEGORIES_TABLE.forEach(
        function (item) {
            item.addEventListener('click', function (e) {
                if (!!e.target.closest('.tests-category-link')) {
                    let link = e.target.closest('.tests-category-link')
                    window.location.href = "/tests-categories/" + link.dataset.id;
                }
            })
        }
    )
}

// alert before attempt the course
if (!!document.querySelector('#start-course-btn')) {
    const btn = document.getElementById('start-course-btn');
    btn.addEventListener('click', function () {
        if (confirm('!   УВАГА   !\n\nПри підтверджені включиться відлік часу* , розпочнеться проходження тестування та буде використана одна спроба** здачі курсу! \n\n * - якщо екзаменатором включений таймер \n ** - всього до сдачі доступні 2 спроби')) {
            window.location.href = btn.dataset.action;
        }
    })
}


